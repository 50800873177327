@import '../../../src/assets/css/variables.module.scss'; 



.tableHelmet {
  margin-top: 10px;
  border-bottom: unset;
  width: 60% !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}


.cursor{
  cursor: pointer;
}

.itemName{
  font-size: 19px !important;
  width: 230px !important;
  color: $primary-dark !important;
  padding: 8px !important;
}

.itemPrice{
  font-size: 16px !important; 
}

.addonPrice{
  color: $primary2 !important;
  font-size: 14px !important;
}

.otherText{
  font-size: 16px !important;
  padding: 8px !important;

}

.addonName{
  font-size: 16px !important;
  color: $primary2 !important;
}

.order-status{
  font-size: 14px;
  padding: 5px;
  text-transform: capitalize;
  border-radius: 20px !important;
}

@media (max-width: 1200px) {
  .tableHelmet {
    margin-top: 10px;
    border-bottom: unset;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .itemName{
    width: 150px !important;
    font-size: 17px !important;
    color: $primary-dark !important;
  }

  .addonName{
    font-size: 16px !important;
    color: $primary2 !important;
  }

  .addonPrice{
    color: $primary2 !important;
    font-size: 14px !important;
  }

  .otherText{
    font-size: 16px !important;
    padding: 8px !important;
  
  }
  

}
