$lite: #fff;
$primary: #B3D234;
$primary2: #A3BF2F;
$secondary: #F03237;
$secondary2: #EE1D23;
$primary2-border: #B3D234;
$primary-dark: #101A21;
$primary-dark2: rgba(237, 88, 120, 0.5);
$primary-dark3: rgba(234, 58, 96, 0.25);
$box-shadow: 0 0 0 0.2rem;
$background-light: #F6F6F6;
$bottom-nav-bg: #CA0603;

