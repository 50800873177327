@import "../../assets/css/variables.module";

.ItemGroupCard {
  margin-bottom: 10px;
}

.MenuItem {
  margin-bottom: 10px;
}

.ItemNameAndPriceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ItemPriceBox{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CategoryName{
  font-size: 30px!important;
  margin-top: 5px;
  margin-bottom: 8px !important;
}

.ItemName{
  font-size: 26px!important;
}

@media (max-width: 1200px) {
  .CategoryName{
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .ItemName{
    font-size: 20px!important;
  }
  .ItemDescription{
    font-size: 16px!important;
    margin: 3px auto !important;
    text-align: start;
  }
  .ItemPriceBox{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}

.AddItemToCart {
  font-size: 38px !important;
  color: $primary;


  &:hover {
   cursor: pointer;
  //  color: $primary-dark3;
  //  transform: scale(0);
  }
}

.MenuItemGroup {
    margin-bottom: 15px;
}

.MenuItemGroupIcon {
    color: $lite;
}

.MenuItemGroupItemCount {
    //color: $lite;
    font-style: italic;
    display: flex;
    align-items: center;
    margin-left: 20px;
}
