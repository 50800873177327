@import "../../assets/css/variables.module";

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media (prefers-reduced-motion: reduce) {
    & {
      transition: none;
    }
  }

  &:hover {
    color: #212529;
    text-decoration: none;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $box-shadow $primary-dark3;
  }
  &.disabled,
  &:disabled {
    opacity: 0.65;
  }


  &.btn-outline-primary {
    color: $primary;
    border-color: $primary-dark;

    &:hover {
      color: $lite;
      background-color: $primary;
      border-color: $primary;
    }
    &:focus,
    &.focus {
      box-shadow: $box-shadow $primary-dark;
    }
    &:disabled,
    &.disabled {
      color: $primary;
      background-color: transparent;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: $box-shadow $primary-dark;
    }
  }

  &.btn-primary {
    color: $lite;
    background-color: $primary;
    border-color: $primary;

    &:hover {
      color: $lite;
      background-color: $primary2;
      border-color: $primary2-border;
    }
    &:focus,
    &.focus {
      color: $lite;
      background-color: $primary2;
      border-color: $primary2-border;
      box-shadow: $box-shadow $primary-dark2;
    }
    &:disabled,
    &.disabled {
      color: $lite;
      background-color: $primary;
      border-color: $primary;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: $lite;
      background-color: #da1741;
      border-color: #ce163e;
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: $box-shadow $primary-dark2; }
  }
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-style {
  padding: 12px 30px;
  color: $lite;
  font-size: 17px;
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 600;
  transition: 0.3s ease-in;
  border-radius: 6px;
}

.btn-outline-style {
  padding: 10px 30px;
  font-size: 17px;
  line-height: 28px;
  text-transform: capitalize;
  font-weight: 600;
  transition: 0.3s ease-in;
  border-radius: 6px;
}
