.navbar {
    padding: .2rem 1rem !important;

    .navbar-brand {
        max-width: 60%;
        max-height: 100%;

        .nav-logo {
            max-width: 100%;
        }
    }

    .navbar-nav {
        .nav-link {
            color: #000000;
            font-size: 1.5em !important;
        }

        .nav-link:hover{
            color: #F3F3F3;
        }

        .nav-item {
            display: flex;
            align-items: center;
        }

        .nav-logo-container {
            max-width: 250px;
            padding: 0 15px;

            .nav-logo {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.navbar-logo-centered {
    .navbar-nav {
        .nav-link{
            padding: .5em 1em;
        }
    }
}

